import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

interface MembersPageProps {
  accessToken: string;
}

interface Member {
  name: string;
  phone: string;
  email: string;
}

const MembersPage: React.FC<MembersPageProps> = ({ accessToken }) => {
  const [members, setMembers] = useState<Member[]>([]);
  const [selectedPhones, setSelectedPhones] = useState<Set<string>>(new Set());
  const location = useLocation();
  const navigate = useNavigate();
  const validatedUsers = location.state?.validatedUsers || [];

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(apiUrl+'/members', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setMembers(response.data);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, [accessToken]);

  useEffect(() => {
    if (validatedUsers.length > 0) {
      setSelectedPhones(new Set(validatedUsers));
    }
  }, [validatedUsers]);

  const handleCheckboxChange = (phone: string) => {
    setSelectedPhones((prevSelectedPhones) => {
      const newSelectedPhones = new Set(prevSelectedPhones);
      if (newSelectedPhones.has(phone)) {
        newSelectedPhones.delete(phone);
      } else {
        newSelectedPhones.add(phone);
      }
      return newSelectedPhones;
    });
  };

  const handleImportMembers = () => {
    navigate('/import-members');
  };

  const handleValidateUsers = () => {
    navigate('/validate-users');
  };

  const handleSendReminders = async () => {
    try {
      const response = await axios.post(apiUrl+'/send-reminders', {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        alert('Reminders sent successfully');
      } else {
        alert('Failed to send reminders');
      }
    } catch (error) {
      console.error('Error sending reminders:', error);
      alert('Error sending reminders');
    }
  };

  const handleRemoveUnmatched = async () => {
    try {
      const response = await axios.delete(apiUrl+'/remove-unmatched', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        alert(`Removed unmatched users successfully`);
      } else {
        alert('Failed to remove unmatched users');
      }
    } catch (error) {
      console.error('Error removing unmatched users:', error);
      alert('Error removing unmatched users');
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">Members</h2>
        <div className="space-x-2">
          <button
            onClick={handleImportMembers}
            className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition duration-300"
          >
            Import members
          </button>
          <button
            onClick={handleValidateUsers}
            className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600 transition duration-300"
          >
            Validate users
          </button>
          <button
            onClick={handleSendReminders}
            className="bg-yellow-500 text-white px-4 py-2 rounded shadow hover:bg-yellow-600 transition duration-300"
          >
            Send reminders to unmatched users
          </button>
          <button
            onClick={handleRemoveUnmatched}
            className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 transition duration-300"
          >
            Remove all unmatched users
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {members.map((member, index) => (
          <div key={index} className="p-4 bg-white rounded shadow">
            <p className="text-gray-700 font-bold">{member.name}</p>
            <p className="text-gray-700">Phone: {member.phone}</p>
            <p className="text-gray-700">Email: {member.email}</p>
            <label className="text-gray-700">
            <input
              type="checkbox"
              checked={selectedPhones.has(member.phone)}
              onChange={() => handleCheckboxChange(member.phone)}
            />
            &nbsp;Validated
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembersPage;