// src/pages/LoginPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/auth';

const apiUrl = process.env.REACT_APP_API_URL;

interface LoginPageProps {
  onLogin: (username: string, accessToken: string) => void;
  onInitStatusChange: (status: 'success' | 'error') => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin, onInitStatusChange }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const data = await login(username, password);
      console.log('Login successful:', data);
      onLogin(username, data.accessToken);
      
      // Call /init API endpoint
      const initResponse = await fetch(apiUrl+'/init', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
        },
      });

      if (initResponse.ok) {
        onInitStatusChange('success');
      } else {
        onInitStatusChange('error');
      }
      navigate('/members');

      // Handle successful login (e.g., store token, redirect)
    } catch (err) {
      setError('Login failed. Please check your username and password.');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-8 rounded shadow">
      <h2 className="text-gray-700 text-2xl mb-6">Login</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Username</label>
          <input
            name="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Password</label>
          <input
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
            required
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;