// src/components/Communities.tsx
import React, { useEffect, useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

interface Community {
  id: number;
  name: string;
}

interface CommunitiesProps {
  accessToken: string;
}

const Communities: React.FC<CommunitiesProps> = ({ accessToken }) => {
  const [communities, setCommunities] = useState<Community[]>([]);

  const fetchCommunities = async () => {
    try {
      const response = await fetch(apiUrl+'/communities', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setCommunities(data);
    } catch (error) {
      console.error('Error fetching communities:', error);
    }
  };

  const refreshCommunities = async () => {
    try {
      await fetch(apiUrl + '/allGroups', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      fetchCommunities();
    } catch (error) {
      console.error('Error refreshing communities:', error);
    }
  };

  const handleInit = async () => {
    try {
      const response = await fetch(apiUrl+'/init', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        console.log('Client initialized');
      } else {
        console.error('Failed to initialize client');
      }
    } catch (error) {
      console.error('Error initializing client:', error);
    }
  };  

  useEffect(() => {
    fetchCommunities();
  }, [accessToken]);

  return (
    <div className="w-full max-w-4xl mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">Communities</h2>
        <button
          onClick={refreshCommunities}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition duration-300"
        >
          Refresh
        </button>
        <button
          onClick={handleInit}
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600 transition duration-300"
        >
          Init
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {communities.map((community) => (
          <div key={community.id} className="p-4 bg-white rounded shadow">
            <h3 className="text-lg font-bold text-black">{community.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Communities;