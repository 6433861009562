// src/pages/HomePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const SignUpCompletePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-teal-400 to-white-500 text-white">
      <main className="flex-1 flex flex-col items-center justify-center text-center px-6">
        <h1 className="text-5xl font-bold mb-4">You are ready to log in!</h1>
      </main>
    </div>
  );
};

export default SignUpCompletePage;