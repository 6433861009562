import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

interface ValidateUsersPageProps {
  accessToken: string;
}

const ValidateUsersPage: React.FC<ValidateUsersPageProps> = ({ accessToken }) => {
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string>('');
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [validatedUsers, setValidatedUsers] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(apiUrl+'/upload-csv', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setMessage('File uploaded successfully.');
        setUploadSuccess(true);
        setValidatedUsers(response.data.validatedUsers);
      } else {
        setMessage('Failed to upload file.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file.');
    }
  };

  const handleContinue = () => {
    navigate('/members', { state: { validatedUsers } });
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-8 rounded shadow text-black">
      <h2 className="text-2xl mb-6">Upload CSV for User Validation</h2>
      <input type="file" accept=".csv" onChange={handleFileChange} className="mb-4" />
      <button onClick={handleUpload} className="w-full bg-blue-500 text-white p-2 rounded">
        Upload
      </button>
      {message && <p className="mt-4 text-gray-700">{message}</p>}
      {uploadSuccess && (
        <>
          <h3 className="text-xl mt-6 mb-4">Validated Users</h3>
          <ul className="list-disc list-inside">
            {validatedUsers.map((phoneNumber, index) => (
              <li key={index}>{phoneNumber}</li>
            ))}
          </ul>
          <button onClick={handleContinue} className="w-full bg-green-500 text-white p-2 rounded mt-4">
            Continue
          </button>
        </>
      )}
    </div>
  );
};

export default ValidateUsersPage;