// src/pages/CommunitiesPage.tsx
import React from 'react';
import Communities from '../components/Communities';

interface CommunitiesPageProps {
  accessToken: string;
}

const CommunitiesPage: React.FC<CommunitiesPageProps> = ({ accessToken }) => {
  return (
    <div className="p-4">
      <Communities accessToken={accessToken} />
    </div>
  );
};

export default CommunitiesPage;