import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import MembersPage from './pages/MembersPage';
import TiersPage from './pages/TiersPage';
import CommunitiesPage from './pages/CommunitiesPage';
import WhatsAppPage from './pages/WhatsAppPage';
import ImportMembersPage from './pages/ImportMembersPage';
import ValidateUsersPage from './pages/ValidateUsersPage';
import Sidebar from './components/Sidebar';
import SignUpPage from './pages/SignUpPage';
import SignUpCompletePage from './pages/SignUpCompletePage';
import Footer from './components/Footer';

const apiUrl = process.env.REACT_APP_API_URL;

interface User {
  username: string;
  accessToken: string;
}

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [initStatus, setInitStatus] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    const username = Cookies.get('username');
    const accessToken = Cookies.get('accessToken');
    if (username && accessToken) {
      setUser({ username, accessToken });
    }
  }, []);

  const handleLogin = (username: string, accessToken: string) => {
    setUser({ username, accessToken });
    Cookies.set('username', username, { expires: 7 }); // Set cookie to expire in 7 days
    Cookies.set('accessToken', accessToken, { expires: 7 }); // Set cookie to expire in 7 days
  };

  const handleLogout = () => {
    setUser(null);
    setDropdownOpen(false);
    Cookies.remove('username');
    Cookies.remove('accessToken');
    setInitStatus(null);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const username = user?.username || '';

  return (
    <Router>
      <div className="min-h-screen flex flex-col bg-gradient-to-r from-teal-400 to-teal-400 text-white">
        {user && <Sidebar onLogout={handleLogout} initStatus={initStatus} />}
        <div className={`flex-1 ${user ? 'ml-64' : ''}`}>
          {!user && (
            <nav className="bg-black bg-opacity-75 p-4 flex justify-between items-center">
              <div className="text-3xl font-bold">Neighbourhood.me</div>
              <ul className="flex space-x-4">
                <li>
                  <Link to="/" className="text-white hover:underline">Home</Link>
                </li>
                <li>
                  <Link to="/login" className="text-white hover:underline">Login</Link>
                </li>
                <li>
                  <Link to="/signup" className="text-white hover:underline">Sign Up</Link>
                </li>
              </ul>
            </nav>
          )}
          <div className="p-4">
            <Routes>
              <Route path="/login" element={<LoginPage onLogin={handleLogin} onInitStatusChange={setInitStatus} />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/signupcomplete" element={<SignUpCompletePage />} />
              <Route path="/" element={<HomePage />} />
              {user && <Route path="/members" element={<MembersPage accessToken={user.accessToken} />} />}
              {user && <Route path="/tiers" element={<TiersPage />} />}
              {user && <Route path="/communities" element={<CommunitiesPage accessToken={user.accessToken} />} />}
              {user && <Route path="/whatsapp" element={<WhatsAppPage accessToken={user.accessToken} />} />}
              {user && <Route path="/import-members" element={<ImportMembersPage accessToken={user.accessToken} />} />}
              {user && <Route path="/validate-users" element={<ValidateUsersPage accessToken={user.accessToken} />} />}
            </Routes>
          </div>
        </div>
        {user && (
          <div className="absolute bottom-4 left-4">
            <span className={`inline-block w-4 h-4 rounded-full ${initStatus === 'success' ? 'bg-green-500' : 'bg-red-500'}`}></span>
          </div>
        )}
         <Footer />
      </div>
    </Router>
  );
}

export default App;