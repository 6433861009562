// src/pages/TiersPage.tsx
import React, { useState } from 'react';

interface Tier {
  id: number;
  icon: string;
  headline: string;
  description: string;
}

const initialTiers: Tier[] = [
  { id: 1, icon: '🔥', headline: 'Tier 1', description: 'Description for Tier 1' },
];

const TiersPage: React.FC = () => {
  const [tiers, setTiers] = useState<Tier[]>(initialTiers);

  const handleAdd = () => {
    const newTier: Tier = {
      id: tiers.length + 1,
      icon: '✨',
      headline: `Tier ${tiers.length + 1}`,
      description: `Description for Tier ${tiers.length + 1}`,
    };
    setTiers([...tiers, newTier]);
  };

  const handleEdit = (id: number) => {
    console.log(`Edit tier with id: ${id}`);
  };

  const handleDuplicate = (id: number) => {
    const tierToDuplicate = tiers.find(tier => tier.id === id);
    if (tierToDuplicate) {
      const newTier: Tier = {
        ...tierToDuplicate,
        id: tiers.length + 1,
        headline: `${tierToDuplicate.headline} (Copy)`,
      };
      setTiers([...tiers, newTier]);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">Tiers</h2>
        <button
          onClick={handleAdd}
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition duration-300"
          disabled
        >
          Add
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {tiers.map(tier => (
          <div key={tier.id} className="p-4 bg-white rounded shadow">
            <div className="flex items-center mb-2">
              <span className="text-2xl mr-2">{tier.icon}</span>
              <h3 className="text-gray-700 font-bold">{tier.headline}</h3>
            </div>
            <p className="text-gray-700 mb-4">{tier.description}</p>
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(tier.id)}
                className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 transition duration-300"
              >
                Edit
              </button>
              <button
                onClick={() => handleDuplicate(tier.id)}
                className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 transition duration-300"
                disabled
              >
                Duplicate
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TiersPage;