import React from 'react';
import { Link } from 'react-router-dom';

interface SidebarProps {
  onLogout: () => void;
  initStatus: 'success' | 'error' | null;
}

const Sidebar: React.FC<SidebarProps> = ({ onLogout, initStatus }) => {
  return (
    <div className="w-64 h-full bg-black bg-opacity-75 text-white fixed flex flex-col justify-between">
      <div>
        <div className="p-4">
          <h1 className="text-2xl font-bold">Neighbourhood.me</h1>
        </div>
        <ul className="mt-4">
          <li className="p-4 hover:bg-blue-600">
            <Link to="/">Home</Link>
          </li>
          <li className="p-4 hover:bg-blue-600">
            <Link to="/members">Members</Link>
          </li>
          <li className="p-4 hover:bg-blue-600">
            <Link to="/whatsapp">WhatsApp</Link>
          </li>
          <li className="p-4 hover:bg-blue-600 cursor-pointer" onClick={onLogout}>
            Log out
          </li>
        </ul>
      </div>
      <div className="p-4">
        {initStatus === 'success' && <span className="text-green-500">●</span>}
        {initStatus === 'error' && <span className="text-red-500">● Reconnecting</span>}
      </div>
    </div>
  );
};

export default Sidebar;