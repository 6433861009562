// src/pages/HomePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-teal-400 to-white-500 text-white">
      <main className="flex-1 flex flex-col items-center justify-center text-center px-6">
        <h1 className="text-5xl font-bold mb-4">Welcome to Neighbourhood.me</h1>
        <p className="text-lg mb-8">Your one-stop solution for managing and monetizing WhatsApp and more.</p>
      </main>
    </div>
  );
};

export default HomePage;