// src/pages/ImportMembersPage.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

interface ImportMembersPageProps {
  accessToken: string;
}

interface Community {
  id: number;
  name: string;
  description: string;
}

const ImportMembersPage: React.FC<ImportMembersPageProps> = ({ accessToken }) => {
  const [communities, setCommunities] = useState<Community[]>([]);
  const [selectedCommunities, setSelectedCommunities] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState<boolean>(true);
  const [importing, setImporting] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroupsAndCommunities = async () => {
      try {
        // Call /allGroups first
        await fetch(apiUrl+'/allGroups', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        // Then call /communities
        const response = await fetch(apiUrl+'/communities', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setCommunities(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupsAndCommunities();
  }, [accessToken]);

  const handleCheckboxChange = (communityId: number) => {
    setSelectedCommunities((prevSelectedCommunities) => {
      const newSelectedCommunities = new Set(prevSelectedCommunities);
      if (newSelectedCommunities.has(communityId)) {
        newSelectedCommunities.delete(communityId);
      } else {
        newSelectedCommunities.add(communityId);
      }
      return newSelectedCommunities;
    });
  };

  const handleImport = async () => {
    const selectedCommunityNames = communities
      .filter((community) => selectedCommunities.has(community.id))
      .map((community) => community.name);

    setImporting(true);
    try {
      const response = await fetch(apiUrl+'/import-selected-groups', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ groupNames: selectedCommunityNames }),
      });
      if (response.ok) {
        console.log('Groups imported successfully');
      } else {
        console.error('Failed to import groups');
      }
    } catch (error) {
      console.error('Error importing groups:', error);
    } finally {
      setImporting(false);
      navigate('/members');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 bg-white p-8 rounded shadow text-black">
      <h2 className="text-2xl mb-6">Select Communities to Import Members</h2>
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="spinner-container">
            <img src="1487.gif" />
          </div>
        </div>
      ) : (
        <div className="mb-4">
          {communities.length === 0 ? (
            <p>No WhatsApp groups found.</p>
          ) : (
            communities.map((community) => (
              <div key={community.id} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`community-${community.id}`}
                  checked={selectedCommunities.has(community.id)}
                  onChange={() => handleCheckboxChange(community.id)}
                  className="mr-2"
                />
                <label htmlFor={`community-${community.id}`} className="text-gray-700">{community.name}</label>
              </div>
            ))
          )}
        </div>
      )}
      {importing ? (
        <div className="flex justify-center items-center">
          <div className="spinner-container">
            <img src="1487.gif" />
          </div>
        </div>
      ) : (
        <div>
          <p>Clicking Import will delete all previously imported members.</p>
          <button
            onClick={handleImport}
            className="w-full bg-blue-500 text-white p-2 rounded mt-4"
          >
            Import
          </button>
        </div>
      )}
    </div>
  );
};

export default ImportMembersPage;