import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black bg-opacity-75 text-white p-4 text-center">
      <p>Support: <a href="mailto:hello@neighbourhood.me" className="underline">hello@neighbourhood.me</a></p>
    </footer>
  );
};

export default Footer;